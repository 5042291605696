import { Controller } from 'stimulus';

export default class extends Controller {


    scelta_canned(e) {
        // var content = atob(e.target.value)
        // var el = document.getElementById('new_ticket_message_rich_editor_trix_input_ticket_message')
        // el.value = content
        // var eldUE = document.getElementById('new_ticket_message_rich_editor')
        // eldUE.innerHTML = content
        var id = e.target.value
        var completeId = 'canned_response_' + id
        var elcontent = document.getElementById(completeId)
        var content = elcontent.innerHTML
        var el = document.getElementById('new_ticket_message_rich_editor_trix_input_ticket_message')
        el.value =  el.value + '\n' + content
        var eldUE = document.getElementById('new_ticket_message_rich_editor')
        eldUE.innerHTML = eldUE.innerHTML + '\n' + content
    }


    quick_update_with_redirect(e) {

      // var selEl = e.target
      var url = e.target.dataset.updateUrl
      var attribute = e.target.dataset.updateAttributeName
      var body = {}
      body['attribute_name'] = attribute
      body['attribute_value'] = e.target.dataset.value

      fetch(url, {
        method: 'PUT',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    }).then(response => {
        if (response.status === 200) {
          window.location.replace(e.target.dataset.redirectUrl);
            // if (response.d)
            // response.json().then(data => {
            //   // if (data.resource.is_read) {
            //   //   e.target.innerText = 'Mark as unread'
            //   //   e.target.style.fontWeight = 400
            //   //   e.target.dataset.value = false
            //   //   return
            //   // } else {
            //   //   e.target.innerText = 'Mark as read'
            //   //   e.target.style.fontWeight = 800
            //   //   e.target.dataset.value = true
            //   //   return
            //   // }
            // }).catch(e => alert('An error occured'))

        } else {
            alert('An error occured during the update')
            return
        }
    }).catch(e => {
        alert('An error occured with your internet connection')
        return
    })
    }

    activate_inline_edit(e) {
        var el = e.target
            // unhide-id
        var unhideId = e.target.dataset.unhideId
        var unhideEl = document.getElementById(unhideId)
        unhideEl.classList.remove('hidden')
            // show the label and also hide the select
        el.classList.add('hidden')
    }


    remove_form(e) {
        var selEl = e.target
        var labelId = e.target.dataset.labelId
        var label = document.getElementById(labelId)
        label.classList.remove('hidden')
        selEl.classList.add('hidden')
    }

    update_ticket(e) {
        // var selEl = e.target
        var selEl = e.target.parentElement;
        var dropDownBtn = e.target.parentElement.parentElement.firstElementChild;

        // all the options
        var options = selEl.children;
        // we get the url to do the update
        var url = e.target.parentElement.dataset.updateUrl
            // the attribute to update
        var attribute = e.target.parentElement.dataset.updateAttributeName
            // we use this to hide the form again after the change
        var labelId = e.target.parentElement.dataset.labelId

        var label = document.getElementById(labelId)

        // check the before selected id
        var prevSelectedId = e.target.parentElement.dataset.beforeStatusId
        var prevSelected = Array.from(options).filter(option => option.value == e.target.value)
        var prevSelectedIndex = Array.from(options).map(e => String(e.value)).indexOf(String(prevSelectedId))
        // search what's the selected element
        var selected = Array.from(options).filter(option => option.value == e.target.value)

        // register an event on blur to remove the select box
        document.getElementById(e.target.parentElement.id).addEventListener('blur', (event) => {
            console.log(event)
            // label.classList.remove('hidden')
            // selEl.classList.add('hidden')
        });


        if (selected.length > 0) {
            var firstSel = selected[0]
                // apply the style for the selected
                // create the body
            var body = {}
            body['attribute_name'] = attribute
            body['attribute_value'] = e.target.dataset["value"]

            // disabling the dropdown button
            dropDownBtn.innerHTML =  `
            ${e.target.parentElement.dataset["name"]}
            <span class="badge bg-light text-dark">
              ${e.target.innerText}
            </span>
            `;
            dropDownBtn.disabled = true;

            fetch(url, {
                method: 'PUT',
                mode: 'same-origin',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(body) // body data type must match "Content-Type" header
            }).then(response => {
                if (response.status === 200) {
                    // ok
                    selEl.style.background = firstSel.style.background
                    dropDownBtn.disabled = false;
                    // change the label value to the selected option (selected style and innerhtml)
                    // label.style.background = firstSel.style.background
                    // label.innerHTML = firstSel.innerHTML
                    // label.classList.remove('hidden')
                    //     // show the label and also hide the select
                    // selEl.classList.add('hidden')
                    return
                } else {
                    // unhide the label without changes
                    e.target.style.background = prevSelected[0].dataset.setColor
                    // selEl.innerHTML = prevSelected[0].innerHTML
                    dropDownBtn.disabled = false;
                    selEl.selectedIndex  = prevSelectedIndex

                    alert('An error occured during the update')
                    return
                }
            }).catch(e => {

                alert('An error occured with your internet connection')
                return
            })
        } else {
            return
        }
    }
}
