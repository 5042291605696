import { Controller } from "stimulus"

/**
 *  This is how to use it
 *   <p
    data-controller="inline-edit"
    data-inline-edit-model="user"
    data-inline-edit-name="email"
    data-inline-edit-input-class="input"
    data-target="inline-edit.source"
    data-action="click->inline-edit#toggle click@window->inline-edit#close">
    <%= @user.email %>
  </p>
 */


export default class extends Controller {
    static targets = ["editable"]

    connect() {

    }


    mouseOverContainer(event) {
      var inline_pencil = document.getElementById('inline_pencil');
      inline_pencil.classList.remove('text-light')
      inline_pencil.classList.add('text-dark')

    }

    mouseOutContainer(event) {
      var inline_pencil = document.getElementById('inline_pencil');
      inline_pencil.classList.add('text-light')
      inline_pencil.classList.remove('text-dark')
    }


    clickPencil(event) {
      let title = document.getElementById('single-title-ticket').innerText
      let titleElement = document.getElementById('single-title-ticket')
      let container = document.getElementById('title_container')
      titleElement.classList.add('d-none')


      let url = event.target.dataset.updateUrl
      let attrName = event.target.dataset.updateAttributeName
      let editor = document.createElement("form")
      editor.setAttribute('id', 'form_ticket_edit_title')
      let input = document.createElement("textarea")


      let button = document.createElement("button")
      let buttonCancel = document.createElement("button")

      input.setAttribute('id', 'form_ticket_edit_title_input')
      editor.append(input)

      button.innerText = 'Update Title'
      buttonCancel.innerText = 'Cancel'

      button.classList = ['btn btn-primary me-3']
      buttonCancel.classList = ['btn btn-light']

      buttonCancel.setAttribute('type', 'button')

      editor.append(button)
      editor.append(buttonCancel)

      // define the spinner element that I will use later on
      var spinner = `<div class="clearfix">
      <div class="spinner-border float-end" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>`
      // set the value as the title
      input.value = title

      // hide the h1
      event.target.classList.add('d-none')
      // container.classList.add('d-none')

      // use some custom style to make the look title form look like the default one
      input.style.border = 0
      input.style.fontSize = '1.125rem'
      input.style.fontWeight = '600'
      input.style.outline = 0
      input.style.width = '100%'
      input.style.overflow = 'hidden'

      // text area to resize based on content
      input.addEventListener('keyup', function() {
        this.textAreaAdjust(input)
      }.bind(this));

      // listen for form submission
      editor.addEventListener('submit', function(eventForm) {
        eventForm.preventDefault()
        // take the value of the first input form
        let val = eventForm.target.children[0].value

        // define the body
        let body = {}
        body['attribute_name'] = attrName
        body['attribute_value'] = val

        // disable the button and show the spinner
        button.setAttribute('disabled', true)
        button.outerHTML = spinner

        // fetch the request
        this.fetchRequest(url, body, (res, error) => {
          if (error) {
            // in case of error show the old value and hide the form
            // eventForm.target.classList.add('d-none')
            editor.remove()
            titleElement.classList.remove('d-none')
            button.setAttribute('disabled', false)
            event.target.classList.remove('d-none')
            return alert(error)
          } else {
            // alert('update tutto ok')
            // in case of success show the new value taken from the request
            // eventForm.target.classList.add('d-none')
            editor.remove()
            titleElement.classList.remove('d-none')
            button.setAttribute('disabled', false)
            event.target.classList.remove('d-none')
            titleElement.innerText = res.resource.title
          }
        })
      }.bind(this));


      buttonCancel.addEventListener('click', function(cancelEvent) {
        cancelEvent.preventDefault()
        editor.classList.add('d-none')
        editor.remove()
        titleElement.classList.remove('d-none')
        button.setAttribute('disabled', false)
        event.target.classList.remove('d-none')
      }.bind(this))

      let selection = window.getSelection()
      // this.editableTarget.insertAdjacentElement('afterend', editor)
      container.insertAdjacentElement('afterend', editor)
      // container.replaceWith(editor)
      // event.target.classList.remove('d-none')
    }

    doubleClickForm(event) {
      event.preventDefault()
      // get what i need from dataset
      let url = event.target.dataset.updateUrl
      let attrName = event.target.dataset.updateAttributeName

      if (event.target.id == "inline_pencil") {
        console.log("cliccato la matita")
        return
      }
      // create the form element
      let editor = document.createElement("form")
      let input = document.createElement("textarea")
      let button = document.createElement("button")
      input.setAttribute('id', 'input_form_ticket_edit_title')
      editor.append(input)
      button.innerText = 'Update'
      button.classList = ['btn btn-primary']
      editor.append(button)

      // define the spinner element that I will use later on
      var spinner = `<div class="clearfix">
      <div class="spinner-border float-end" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>`
      // set the value as the title
      input.value = event.target.innerText

      // hide the h1
      event.target.classList.add('d-none')

      // use some custom style to make the look title form look like the default one
      input.style.border = 0
      input.style.fontSize = '1.125rem'
      input.style.fontWeight = '600'
      input.style.outline = 0
      input.style.width = '100%'
      input.style.overflow = 'hidden'

      // text area to resize based on content
      input.addEventListener('keyup', function() {
        this.textAreaAdjust(input)
      }.bind(this));

      // listen for form submission
      editor.addEventListener('submit', function(eventForm) {
        eventForm.preventDefault()
        // take the value of the first input form
        let val = eventForm.target.children[0].value

        // define the body
        let body = {}
        body['attribute_name'] = attrName
        body['attribute_value'] = val

        // disable the button and show the spinner
        button.setAttribute('disabled', true)
        button.outerHTML = spinner

        // fetch the request
        this.fetchRequest(url, body, (res, error) => {
          if (error) {
            // in case of error show the old value and hide the form
            eventForm.target.classList.add('d-none')
            event.target.classList.remove('d-none')
            button.setAttribute('disabled', false)
            return alert(error)
          } else {
            // alert('update tutto ok')
            // in case of success show the new value taken from the request
            eventForm.target.classList.add('d-none')
            event.target.classList.remove('d-none')
            button.setAttribute('disabled', false)
            event.target.innerText = res.resource.title
          }
        })
      }.bind(this));

      let selection = window.getSelection()
      this.editableTarget.insertAdjacentElement('afterend', editor)
    }

    textAreaAdjust(element) {
      element.style.height = "1px";
      element.style.height = (25+element.scrollHeight)+"px";
    }

    fetchRequest(url, body, cb) {
      fetch(url, {
        method: 'PUT',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
      }).then(response => {
        if (response.status === 200) {
          response.json().then(res => cb(res, undefined))
        } else {
            // alert('An error occured during the update')
            return cb(undefined, 'An error occured during the update')
        }
    }).catch(e => {
        return cb(undefined, 'An error occured during the update')
    })
    }

}
