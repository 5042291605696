import { Controller } from 'stimulus';
import linkifyElement from 'linkifyjs/element';

export default class extends Controller {


  async connect(){
    console.log('Linkify controller')
    try {
      linkifyElement(document.getElementById('linkify'), {
        target: "_blank"
      }, document);
    } catch (error) {
      console.error('error with linkify')
    }
  }
}
