// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("trix")
require("@rails/actiontext")

require("chartkick")
require("chart.js")



Rails.start()
ActiveStorage.start()


// import "bootstrap"

import "../stylesheets/application.scss"


import 'controllers'
import 'active_storage_drag_and_drop'
import '@yaireo/tagify/src/tagify.scss'

import '@mdi/font/css/materialdesignicons.css'
import '@icon/dripicons/dripicons.css'

// import 'active_storage_drag_and_drop'
// Hyper theme js files
// require("../hyper/hyper-setup");


// import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/carousel'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
// import 'bootstrap/js/src/toast'

import { Tooltip } from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
});



// document.addEventListener("turbolinks:load", function(event) {
//   console.log('turbolinks:load daje')
//   let cardElement = document.querySelector('#card-element')
//   if (cardElement != null) {
//     setupStripe()
//   }
// })
// // create stripe
// function setupStripe() {
//   console.log('setup stripe')
//   const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content')
//   const stripe = Stripe(stripeKey)
//   const elements = stripe.elements()
//   const card = elements.create('card')
//   card.mount('#card-element')

//   var displayError = document.getElementById('card-errors')
//   card.addEventListener('change', function(event) {
//     if (event.error) {
//       displayError.textContent = event.error.message
//     } else {
//       displayError.textContent = ''
//     }

//   })
// }

document.addEventListener("turbolinks:load", function(event) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })

    /* ==== Enable Bootstrap Alert ====== */
    var alertList = document.querySelectorAll('.alert')
    alertList.forEach(function(alert) {
        new bootstrap.Alert(alert)
    })


    /* ===== Responsive Sidepanel ====== */
    const sidePanelToggler = document.getElementById('sidepanel-toggler');
    const sidePanel = document.getElementById('app-sidepanel');
    const sidePanelDrop = document.getElementById('sidepanel-drop');
    const sidePanelClose = document.getElementById('sidepanel-close');

    window.addEventListener('load', function() {
        responsiveSidePanel();
    });

    window.addEventListener('resize', function() {
        responsiveSidePanel();
    });


    function responsiveSidePanel() {
        let w = window.innerWidth;
        if (w >= 1200) {
            // if larger
            //console.log('larger');
            sidePanel.classList.remove('sidepanel-hidden');
            sidePanel.classList.add('sidepanel-visible');

        } else {
            // if smaller
            //console.log('smaller');
            sidePanel.classList.remove('sidepanel-visible');
            sidePanel.classList.add('sidepanel-hidden');
        }
    };

    sidePanelToggler.addEventListener('click', () => {
        if (sidePanel.classList.contains('sidepanel-visible')) {
            console.log('visible');
            sidePanel.classList.remove('sidepanel-visible');
            sidePanel.classList.add('sidepanel-hidden');

        } else {
            console.log('hidden');
            sidePanel.classList.remove('sidepanel-hidden');
            sidePanel.classList.add('sidepanel-visible');
        }
    });



    sidePanelClose.addEventListener('click', (e) => {
        e.preventDefault();
        sidePanelToggler.click();
    });

    sidePanelDrop.addEventListener('click', (e) => {
        sidePanelToggler.click();
    });



    /* ====== Mobile search ======= */
    const searchMobileTrigger = document.querySelector('.search-mobile-trigger');
    const searchBox = document.querySelector('.app-search-box');

    // searchMobileTrigger.addEventListener('click', () => {

    // 	searchBox.classList.toggle('is-visible');

    // 	let searchMobileTriggerIcon = document.querySelector('.search-mobile-trigger-icon');

    // 	if(searchMobileTriggerIcon.classList.contains('fa-search')) {
    // 		searchMobileTriggerIcon.classList.remove('fa-search');
    // 		searchMobileTriggerIcon.classList.add('fa-times');
    // 	} else {
    // 		searchMobileTriggerIcon.classList.remove('fa-times');
    // 		searchMobileTriggerIcon.classList.add('fa-search');
    // 	}



    // });
});


// require("trix")
// require("@rails/actiontext")
// import "controllers"
