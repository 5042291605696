import { Controller } from "stimulus"
import * as timeago from 'timeago.js';

export default class extends Controller {
  connect() {
    console.log('ticket_row_controller')
    console.log('connesso')
    timeago.render(document.querySelectorAll('.timeago-to-convert'));
  }
}
