import { Controller } from 'stimulus';
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  connect() {
    console.log("this is the custom field controller")
    var inputEl = document.getElementById('custom_input_choices')
    var tagify = new Tagify(inputEl)
    if (inputEl.dataset.presentValues) {
      var values = inputEl.dataset.presentValues
      var arr = values.split(',')
      tagify.removeAllTags()
      tagify.addTags(arr)

    }
  }

  scelta(e) {
    console.log(e.target.value)
    var el = document.getElementsByClassName(e.target.value)
    var elements = document.getElementsByClassName('specific-fields')
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add('hidden');
    }
    el[0].classList.remove('hidden')



  }
}
