import { Controller } from 'stimulus';

import GLightbox from 'glightbox';
import "glightbox/dist/css/glightbox.css"

export default class extends Controller {

  async connect(){
    console.log('glightbox init')
    const lightbox = GLightbox({});
  }
}
