import { Controller } from 'stimulus';
import {Offcanvas} from 'bootstrap';

export default class extends Controller {
  connect() {
  }

  toggle_sidebar(e) {
    console.log(e.currentTarget)
    var offCanvasId = document.getElementById(e.currentTarget.dataset.offcanvasId)
    console.log(offCanvasId)
    // offCanvasId.style.visibility = 'visible'
    // offCanvasId.classList.add('show')
    new Offcanvas(offCanvasId)
    // var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
    //   return new bootstrap.
    // })
  }
}
