import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'clientSecret','name','cardContainer']

  initialize(){
    console.log('payment_methods_controller.js initialized!');
    this.stripe = Stripe("pk_test_51IuYrJETAknCF0ZRCfSPhkaAUnd2SQ0MTaDf4WK1pLkumfIc3kSXlsS48hg9KZsjZYnMSBH3lH5gXhv0pZ7hgDje00CvLzZHVt");
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      style:
      {
        base: {
          lineHeight: '1.429'
        }
      }
    });
    this.card.mount(`#${this.cardContainerTarget.getAttribute("id")}`);
  }

  submitBtn(e){
    e.preventDefault();
    this.stripe.confirmCardSetup(this.clientSecretTarget.value,{
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        this.formTarget.action = "/subscription/payment_methods"
        this.formTarget.method = "POST"
        this.formTarget.submit();
      } else {
        alert(result.error.message);
      }
    });
  }
}
