import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form','clientSecretInput','nameInput','cardContainer','cardError'
  ]

  initialize() {
    console.log('Inside subscription/checkout_controller.js');
    // bindings of `this` to the functions
    this._handleCardError = this._handleCardError.bind(this);

    this.stripe = Stripe("pk_test_51IuYrJETAknCF0ZRCfSPhkaAUnd2SQ0MTaDf4WK1pLkumfIc3kSXlsS48hg9KZsjZYnMSBH3lH5gXhv0pZ7hgDje00CvLzZHVt");
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      style:
      {
        base: {
          lineHeight: '1.429'
        }
      }
    });
    this.card.mount(`#${this.cardContainerTarget.getAttribute("id")}`);
    this.card.on("change", this._handleCardError);
  }

  _handleCardError(event){
    if(event.error){
      this.cardErrorTarget.textContent = event.error.message;
    } else {
      this.cardErrorTarget.textContent = '';
    }
  }

  submitBtn(e){
    e.preventDefault();
    var that = this;
    this.stripe.confirmCardPayment(this.clientSecretInputTarget.value, {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameInputTarget.value
        }
      }
    }).then(result => {
      if(result.error){
        alert(result.error.message);
      } else {
        this.formTarget.action = '/subscription/checkout';
        this.formTarget.submit();
      }
    }).catch(err => {
        if (err){
          alert(err.message);
        }
      })
  }

  cancelBtn(e){
    e.preventDefault();

    this.formTarget.action = "/subscription/plans/destroy"

    const hiddenDelInput = document.createElement('input');
    hiddenDelInput.type = "hidden";
    hiddenDelInput.name = "_method";
    hiddenDelInput.value = "delete";

    this.formTarget.prepend(hiddenDelInput);
    this.formTarget.submit();
  }
}
