import { Controller } from "stimulus"
import {loadStripe} from '@stripe/stripe-js';

export default class extends Controller {
  async connect() {
    console.log('setup stripe controller')
    const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content')
    // const stripe = Stripe(stripeKey)
    const stripe = await loadStripe(stripeKey);

    const elements = stripe.elements()
    const card = elements.create('card')
    card.mount('#card-element')

    let newCardLink = document.querySelector('#use-new-card')
    if (newCardLink !== null ) {
      newCardLink.addEventListener('click', (event) => {
        event.preventDefault()

        document.querySelector('#payment_form').classList.remove('d-none')
        document.querySelector('#existing-card').classList.add('d-none')
      })
    }



    var displayError = document.getElementById('card-errors')
    card.addEventListener('change', function(event) {
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    const form = document.getElementById('payment_form')
    let paymentIntentId = form.dataset.paymentIntent
    let setupIntentId = form.dataset.setupIntent

    if (paymentIntentId) {
      if (form.dataset.status == 'requires_action' || form.dataset.status == 'requires_source_action' ) {
        stripe.confirmCardPayment(paymentIntentId, {setup_future_usage: 'off_session'}).then(
          (res) => {
            if (res.error) {
              displayError.textContent = res.error.message
              form.querySelector('#card-details').classList.remove('d-none')
            } else {
                form.submit()
            }
          }
        )
      }
    }


    form.addEventListener('submit', (event) => {
      event.preventDefault()
      let name = form.querySelector('#name_on_card').value
      let payment_data = {
        payment_method_data: {
          card: card,
          billing_details: {
            name: name
          }
        }
      }

      // complete a payment intent
      if (paymentIntentId) {
        stripe.confirmCardPayment(paymentIntentId, {
          payment_method: payment_data.payment_method_data,
          setup_future_usage: 'off_session',
          save_payment_method: true
        }).then(res => {
          if (res.error) {
            displayError.textContent = res.error.message
            form.querySelector('#card-details').classList.remove('d-none')
          } else {
            form.submit()
          }
        })
      } else if(setupIntentId) {
        // updating a card or subscripting with a trial (using a setup intent)
        stripe.confirmCardSetup(setupIntentId, {
          payment_method: payment_data.payment_method_data
        }).then(result => {
          if (result.error) {
            displayError.textContent = result.error.message
          } else {
            this.addHiddenField(form, 'payment_method_id', result.setupIntent.payment_method)
            // form.querySelector('#stripe-token').value = result.paymentMethod.id
            form.submit()
          }
        })

      } else {
        // Subscribing with no trial
        payment_data.payment_method_data.type = 'card'
        stripe.createPaymentMethod(payment_data.payment_method_data).then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message
          } else {
            this.addHiddenField(form, 'payment_method_id', result.paymentMethod.id)
            // form.querySelector('#stripe-token').value = result.paymentMethod.id
            form.submit()
          }
        })
      }

    })

  }

  addHiddenField(form, name, value) {
    let input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)
    input.setAttribute('value', value)
    form.appendChild(input)
  }
}
