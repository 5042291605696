import { Controller } from 'stimulus';
import Tagify from '@yaireo/tagify'
import linkifyElement from 'linkifyjs/element';
import GLightbox from 'glightbox';
import "glightbox/dist/css/glightbox.css"

export default class extends Controller {
  static targets = ['tagInput']

  async connect(){

    this.whitelist_tags = await this.get_tags_list();

    this.tagify = new Tagify(this.tagInputTarget, {
      keepInvalidTags: true,
      whitelist: this.whitelist_tags,
      transformTag: (data) => this._transform_tag(data),
      dropdown: {
        enabled: 0,             // <- show suggestions on focus
        closeOnSelect: false,    // <- do not hide the suggestions dropdown once an item has been selected
        searchKeys: ['id', 'value'],
        mapValueTo: 'value'
        // classname: "badge"
      },
      callbacks: {
        click: (e) => this.on_tag_click(e.detail),
        add: (e) => this.on_tag_changes(e),
        remove: (e) => this.on_tag_changes(e),
        "dropdown:select": (e) => this.on_tag_changes(e),
        // change: (e) => this.on_tag_changes(e)
      }
    })

    this.tagify.on('add', (e) => {
      console.log('tag added !');
    })

    this.tagify.on('invalid', (e) => {
        console.log(e, e.detail);
    })

    // try {
    //   linkifyElement(document.getElementById('linkify-contents'), {
    //     target: "_blank"
    //   }, document);
    // } catch (error) {
    //   console.error('error with linkify')
    // }
    // start linkify for all texts

    const lightbox = GLightbox({});
  }
  on_tag_click(tag) {
    console.log(tag.data.value)
    // TODO: bring the user to another page
  }

  on_tag_changes(e) {
    try {
      if (!this.tagInputTarget.value) {
        var newArr = []
      } else {
        var arr = JSON.parse(this.tagInputTarget.value)
        var newArr = arr.map(function(obj) {
          return obj['value']
        });
      }



      var url = this.tagInputTarget.dataset.updateUrl
      var loaderId = this.tagInputTarget.dataset.loaderId
      var loaderEl = document.getElementById(loaderId)
      // get all the values and pushing them in a new array


      var body = {
        'attribute_name': 'tag_list',
        'attribute_value': newArr
      }

      if(loaderEl) {
        loaderEl.classList.remove('d-none')
      }

      fetch(url, {
        method: 'PUT',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
      }).then(response => {
        // if (response.status === 200) {
          if(loaderEl) {
            loaderEl.classList.add('d-none')
          }
        return
        // }
      }).catch(error => console.log(error))

    } catch (error) {
      console.log('An Error during update ' + error)
      if(loaderEl) {
        loaderEl.classList.add('d-none')
      }
    }
  }

  async get_tags_list() {
    const url = this.tagInputTarget.dataset.searchUrl;
    const response = await fetch(url);
    const tags = await response.json();
    return tags;
  }

  _transform_tag(tag) {
    let found_tag = this.whitelist_tags.find((tg) => tg.value.toUpperCase() === tag.value.toUpperCase());

    if (found_tag && found_tag.color) {
      tag.style = "--tag-bg:" + found_tag.color;
    } else {
      tag.style = "--tag-bg: #eef2f7";
    }
  }
}
