import { Controller } from 'stimulus';

export default class extends Controller {

  connect(){
    this.body = document.getElementsByTagName("body")[0];
    // method bindings
    this._bind_this();
    console.log('this is the navbar controller')
  }

  _bind_this(){
    this._collapse_sidebar = this._collapse_sidebar.bind(this);
    this._expand_sidebar = this._expand_sidebar.bind(this);
  }

  handle_sidebar(e){
    console.log(this.body);
    this.body.classList.toggle('sidebar-enable');

    if (this.body.hasAttribute("data-leftbar-compact-mode")){
      this._expand_sidebar()
      console.log("Attribute exists");
    } else {
      this._collapse_sidebar()
      console.log("Attribute does not exist");
    }
  }

  _collapse_sidebar(){
    this.body.setAttribute('data-leftbar-compact-mode', 'condensed');
    var elE = document.getElementById('logo-sm')
    var el = document.getElementById('logo-lg')
    el.classList.add('hidden')
    elE.classList.remove('hidden')
  }

  _expand_sidebar(){
    this.body.removeAttribute('data-leftbar-compact-mode');
    var el = document.getElementById('logo-sm')
    var elE = document.getElementById('logo-lg')
    el.classList.add('hidden')
    elE.classList.remove('hidden')
  }
}
