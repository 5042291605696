import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["form","priceId"]

  checkout(e){
    e.preventDefault();
    this.priceIdTarget.value = e.target.dataset.id;
    this.priceIdTarget.disabled = false;
    this.formTarget.submit();
  }
}
