import { Controller } from 'stimulus';
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  connect() {
    console.log("this is the tabs controller")
  }

  change_url(e) {
    console.log(e.currentTarget)
    var tabName = e.currentTarget.dataset.targetTab
    console.log(tabName)
    var newUrl = `${e.currentTarget.dataset.baseUrl}?tab=${tabName}`
    window.history.pushState(null, null, newUrl);
  }
}
