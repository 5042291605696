import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["form","headerModal"]

  handleCreate(e) {
    this.headerModalTarget.innerText = "Create new tag";
    if(this.formTarget.elements[0].type == "hidden"){
      this.formTarget.elements[0].remove();
    }
    this.formTarget.action = "/app/preferences/tags";
    this.formTarget.reset();
  }
}
